import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Blast Lottery',
  defaultTitle: 'Blast Lottery',
  description: 'A revolutionary decentralised, crypto betting platform and blockchain playgroud, the first game is a lottery.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@BlastLottery',
    site: '@BlastLottery',
  },
  openGraph: {
    title: "BlastLottery",
    description: 'Blast Lottery',
  },
}
