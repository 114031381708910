import { useTranslation } from '@pancakeswap/localization'
import { Button, Flex, Text } from '@pancakeswap/uikit'
import { ErrorBoundary as SErrorBoundary } from '@sentry/nextjs'
import Page from 'components/Layout/Page'
import { useCallback } from 'react'

export function SentryErrorBoundary({ children }) {
  const { t } = useTranslation()
  const handleOnClick = useCallback(() => window.location.reload(), [])
  return (
    <SErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel('fatal')
      }}
      fallback={({ eventId, componentStack, error }) => {
        return (
          <Page>
            <Flex flexDirection="column" justifyContent="center" alignItems="center">
              <Text mb="16px">{t('Oops, something wrong.')}</Text>
              <Button style={{ borderRadius: 8, background: '#31CCB4', height: 60, color: '#000' }} onClick={handleOnClick}>{t('Click here to reset!')}</Button>
            </Flex>
            {process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' && (
              <pre>
                <code>{error?.message}</code>
                <code>{componentStack}</code>
              </pre>
            )}
          </Page>
        )
      }}
    >
      {children}
    </SErrorBoundary>
  )
}
