import { ChainId, chainNames } from '@pancakeswap/chains'
import { MULTICALL3_ADDRESS } from '@pancakeswap/multicall'
import memoize from 'lodash/memoize'
import { mainnet } from 'wagmi'

export const blastSepolia = {
  id: 168587773,
  network: 'blast-sepolia',
  name: 'Blast Sepolia',
  nativeCurrency: {
    name: 'Blast Sepolia Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/blast_testnet_sepolia'],
    },
    public: {
      http: ['https://rpc.ankr.com/blast_testnet_sepolia'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'Blastscan',
      url: 'https://testnet.blastscan.io/',
    },
    default: {
      name: 'Blastscan',
      url: 'https://testnet.blastscan.io/',
    },
  },
  contracts: {
    multicall3: {
      address: MULTICALL3_ADDRESS,
      blockCreated: 6507670,
    },
  },
} as any

export const blastMainnet = {
  id: 81457,
  network: 'blast',
  name: 'Blast',
  nativeCurrency: {
    name: 'Blast Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.blast.io'],
    },
    public: {
      http: ['https://rpc.blast.io'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'Blastscan',
      url: 'https://blastscan.io/',
    },
    default: {
      name: 'Blastscan',
      url: 'https://blastscan.io/',
    },
  },
  contracts: {
    multicall3: {
      address: MULTICALL3_ADDRESS,
      blockCreated: 6507670,
    },
  },
} as any

export const CHAIN_QUERY_NAME = chainNames

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName.toLowerCase()]: chainId as unknown as ChainId,
    ...acc,
  }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

// const bsc = {
//   ...bsc_,
//   rpcUrls: {
//     ...bsc_.rpcUrls,
//     public: {
//       ...bsc_.rpcUrls.public,
//       http: ['https://bsc-dataseed.binance.org/'],
//     },
//     default: {
//       ...bsc_.rpcUrls.default,
//       http: ['https://bsc-dataseed.binance.org/'],
//     },
//   },
// } satisfies Chain

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.POLYGON_ZKEVM,
  ChainId.POLYGON_ZKEVM_TESTNET,
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.LINEA_TESTNET,
  ChainId.LINEA,
  ChainId.BASE,
  ChainId.BASE_TESTNET,
  ChainId.OPBNB,
  ChainId.OPBNB_TESTNET,
  ChainId.BLAST_MAINNET,
  ChainId.BLAST_TESTNET,
]

export const CHAINS = [mainnet, blastSepolia]
