import { Trans, useTranslation } from '@pancakeswap/localization'
import {
  Box,
  DropdownMenu,
  DropdownMenuItemType,
  Flex,
  useMatchBreakpoints
} from '@pancakeswap/uikit'
import { useTheme as useNextTheme } from 'next-themes'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { styled, useTheme } from 'styled-components'

const StyledMenuItem = styled('div') <{ $isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  color: ${({ $isActive }) => ($isActive ? '#FBB904' : 'rgba(255,255,255,0.4)')};
  font-size: 24px;
  font-weight: bold;

  padding: 0 6px;
  height: 48px;
  margin-right: 32px;

  &:hover {
    opacity: 0.65;
  }
`

interface MenuConfigItemType {
  label: string | React.ReactNode
  href: string
  type?: DropdownMenuItemType
}

interface MenuConfigType {
  title: string | React.ReactNode
  href: string
  items?: MenuConfigItemType[]
}

const MenuConfig: MenuConfigType[] = [
  { title: <Trans>Lottery</Trans>, href: '/' },
  { title: <Trans>How to play</Trans>, href: '/how' },
]

const Header = ({ rightSide }: { rightSide: JSX.Element }) => {
  const theme = useTheme()
  const { setTheme } = useNextTheme()
  const nextRouter = useRouter()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { isDesktop } = useMatchBreakpoints()

  return (
    <Flex height="158px" bg="#14160E" px="16px" alignItems="flex-end" pb="24px" justifyContent="space-between" zIndex={9} borderBottom="solid 1px #515B40">
      <Flex>
        <img style={{ width: 148, height: 88, flexShrink: 0 }} src="/images/blast/main_logo.png" className="main-logo" width="148px" height="88px" alt="" />

        <Flex pl={['10px', '10px', '10px', '50px']}>
          {MenuConfig.map((menu) => (
            <Flex key={menu.href}>
              {menu.items ? (
                <DropdownMenu items={menu.items}>
                  <NextLink href={menu.href} passHref>
                    <StyledMenuItem
                      $isActive={menu.items.findIndex((item) => item.href === nextRouter.pathname) !== -1}
                    >
                      {menu.title}
                    </StyledMenuItem>
                  </NextLink>
                </DropdownMenu>
              ) : (
                <Box display="flex" style={{ alignItems: 'center' }}>
                  <NextLink href={menu.href} passHref>
                    <StyledMenuItem $isActive={nextRouter.pathname === menu.href}>{menu.title}</StyledMenuItem>
                  </NextLink>
                </Box>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex height="88px" alignItems="center">
        {rightSide}
      </Flex>
    </Flex>
  )
}

export default Header
