import { styled } from "styled-components";
import Box from "../Box/Box";
import IconButton from "../Button/IconButton";
import Input from "../Input/Input";
import Text from "../Text/Text";
import { BalanceInputProps } from "./types";

export const SwitchUnitsButton = styled(IconButton)`
  width: 16px;
`;

export const UnitContainer = styled(Text)`
  margin-left: 4px;
  text-align: right;
  color: ${({ theme }) => theme.colors.textSubtle};
  white-space: nowrap;
`;

export const StyledBalanceInput = styled(Box) <{ isWarning: BalanceInputProps["isWarning"] }>`
  background-color: #3E3F23;
  border: 1px solid #FBB904;
  border-radius: 8px;
  padding: 0 16px;
  position: relative;
  height: 88px;
  line-height: 88px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:after {
    content: 'Tickets';
    position: absolute;
    top: 0;
    left: 20px;
    margin: auto;
    z-index: 2;
    font-size: 18px;
    font-weight: bold;
    color: #6C6E2D;
  }

  &.withdraw {
    background-color: #0A2C3B;
    border-color: #519aba;
    justify-content: flex-start;

    input {
      font-size: 24px;
      background: none;

      ::placeholder {
        color: #e5e5e5;
      }
    }

    &:after {
      content: '';
    }
  }

`;

export const StyledInput = styled(Input) <{ textAlign?: string }>`
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  text-align: ${({ textAlign = "right" }) => textAlign};
  border: none;
  height: 100%;
  font-size: 40px;
  font-weight: bold;
  color: #fff !important;

  ::placeholder {
    color: #fff !important;
    opacity: 0.5;
  }

  &:focus:not(:disabled) {
    box-shadow: none;
  }
`;
